import React, { useEffect } from 'react'
import { Tab } from '@scuf/common'
import { Container } from './Preferences.styles'
import PropTypes from 'prop-types'
import Tabs from './Preferences.constants'
import { get } from 'lodash'
import NewReportForm from './components/preferences-email/components/new-report-form'

const PreferencesPage = ({
  flags,
  t,
  setBreadcrumb,
  selectedOrgId,
  getAlerts,
  saveAlerts,
  updateSites,
  openModal,
  getSites,
  hierarchyRequest,
  newReportModalOpen,
  toggleUpdate,
  featureList
}) => {
  useEffect(() => {
    getSites()
    hierarchyRequest()
    setBreadcrumb([])
    getAlerts(selectedOrgId)
  }, [selectedOrgId])
  const hasFeatureAccess = (deviceType, type) => flags.premiumLevelDefault || featureList.includes('alerts.' + deviceType + '.' + type)

  return (
    <>
      { !newReportModalOpen ?
        <Container>
          <h2>{t('NotificationsPreferences')}</h2>
          <Tab defaultActiveIndex={Tabs.length - 1} onTabChange={(activeIndex) => { }} style={{ height: 'calc(100% - 4rem)' }}>
            {
              Tabs
                .filter(tabData => get(flags, tabData.condition, true))
                .map(tabData =>
                  <Tab.Pane
                    key={tabData.key}
                    title={t(tabData.title)}
                  >
                    <tabData.component
                      saveAlerts={saveAlerts}
                      selectedOrgId={selectedOrgId}
                      updateSites={updateSites}
                      openModal={openModal}
                      toggleUpdate={toggleUpdate}
                      hasFeatureAccess={hasFeatureAccess}
                    />
                  </Tab.Pane>
                )
            }
          </Tab>
        </Container> :
        <NewReportForm hasFeatureAccess={hasFeatureAccess}/>

      }
    </>

  )
}

PreferencesPage.propTypes = {
  t: PropTypes.func
}

PreferencesPage.defaultProps = {
  setBreadcrumb: s => s,
  t: l => l
}

export default PreferencesPage
