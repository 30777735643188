import { Icon, SidebarLayout } from '@scuf/common'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components/macro'

import { UserApi } from 'Services'
import { isMobile } from 'Utils/constants'
import { redirectToWorkforceIntelligence, redirectToLogAnalyzer } from './MenuOptions.helper'

import { defaultFlags, flagsPropTypes } from '../../Utils/launch-darkly-flags'
import RobotLogo from '../../Assets/Robot.png'

const { Sidebar } = SidebarLayout

const StyledSidebar = styled(Sidebar)`
  max-height: calc(100vh - 6rem) !important;
  & .sidebar-icon-wrap{
    margin-left: .2rem;
  }
  @media (max-width: 768px) {
    display: ${props => props.collapsed ? 'none' : 'block'};
    position: fixed;
    height: 100vh !important;
  }
`

const ManagementSection = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

const AssetsIcon = styled.img`
`
class MenuOptions extends Component {
  static propTypes = {
    flags: flagsPropTypes,
    t: PropTypes.func,
    isAdmin: PropTypes.bool,
    history: PropTypes.object.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string
    }).isRequired,
    collapsed: PropTypes.bool,
    userId: PropTypes.string
  }

  static defaultProps = {
    flags: defaultFlags,
    t: key => key
  }

  constructor(props) {
    super(props)
    this.state = {
      reportsCollapsed: false
    }
    this.renderAdminSubmenu = this.renderAdminSubmenu.bind(this)
  }

  getFilters = async (type, menuValueCollapsable, changeMenuCollapsed) => {
    const { history, userId, flags } = this.props
    if (type === 'open') { changeMenuCollapsed('assetsOpts', !menuValueCollapsable.assetsOpts) }
    if (type != 'open') {
      if (flags.saveFilters) {
        if (!window.location.pathname.includes(type)) {
          await UserApi.getFilters({ section: type, userId })
            .then(res => {
              let search = ''
              if (res.ok) {
                search = res.data.search || ''
              }
              history.push({
                pathname: `/assets/management/${type}`,
                search
              })
            })
            .catch(_ => null)
        }
      } else {
        history.push(`/assets/management/${type}`)
      }
    }
  }

  renderAdminSubmenu(menuValueCollapsable, changeMenuCollapsed) {
    const {
      flags,
      t,
      history,
      match: { path },
      canUserView,
      canSiteView,
      isDashboardAdmin,
      canViewOnlyAssignedAsset,
      canAssetsConfigurationHide,
      canDevicesOutRangeHide,
      canViewOperationsSupport
    } = this.props
    return (
      <Sidebar.Submenu
        content={t('Admin')}
        icon='settings'
        iconRoot='building'
        id = 'adminOption'
        onClick={() => {
          changeMenuCollapsed('adminOpts', !menuValueCollapsable.adminOpts)
        }}
        open={menuValueCollapsable.adminOpts}
      >
        {/* <Sidebar.Item active={window.location.pathname === '/admin/provisions'} onClick={() => history.push('/admin/provisions')}>Provision Requests</Sidebar.Item>
            <Sidebar.Item active={window.location.pathname === '/admin/registrations'} onClick={() => history.push('/admin/registrations')}>Registration Requests</Sidebar.Item> */}
        {canUserView && !canViewOnlyAssignedAsset && <Sidebar.Item
          active={path === '/admin/user_management'}
          content={t('User_Management')}
          icon={<div className='icon-userconf' />}
          id = 'userManagementOption'
          onClick={() => history.push('/admin/user_management')}
        />}
        {flags.legacySiteManagement && !canViewOnlyAssignedAsset && (
          <Sidebar.Item
            active={path === '/admin/legacy_site_management'}
            content={t('Location_Hierarchy')}
            icon='organization'
            iconRoot='common'
            onClick={() => history.push('/admin/legacy_site_management')}
          />
        )}
        {flags.siteManagementPreview && canSiteView && !canViewOnlyAssignedAsset && (
          <Sidebar.Item
            active={path === '/admin/site_management'}
            content={t('Site_Management')}
            icon={<div className='icon-siteconf' />}
            id = 'siteManagementOption'
            onClick={() => history.push('/admin/site_management')}
          />
        )}
        {canViewOperationsSupport &&
          <Sidebar.Item
            active={path === '/admin/OperationsSupportLanding'}
            content={t('OperationsSupport')}
            icon='user-operations'
            id = 'operationsSupportOption'
            onClick={() => history.push('/admin/OperationsSupportLanding')}
          />
        }
        {/* {flags.dashboardManagement && isDashboardAdmin && <Sidebar.Item
          active={path === '/admin/dashboard_management'}
          content={t('Dashboard_Management')}
          icon='graph-drop'
          onClick={() => history.push('/admin/dashboard_management')}
        />} */}
        {flags.nonIot && (
          canAssetsConfigurationHide
            ? null
            : <Sidebar.Item
              active={path === '/admin/asset_configuration_management'}
              content={t('AssetsConfiguration')}
              icon='asset-configuration'
              iconRoot='common'
              id = 'assetsConfigurationOption'
              onClick={() => history.push('/admin/asset_configuration_management')}
            />
        )}
        {
          canDevicesOutRangeHide
            ? null
            : <Sidebar.Item
              active={path === '/admin/devices_out_of_range'}
              content={t('Devices_Out_Of_Range')}
              icon='server-offline'
              iconRoot='common'
              id = 'devicesOutOfRangeOption'
              onClick={() => history.push('/admin/devices_out_of_range')}
            />
        }
      </Sidebar.Submenu>
    )
  }

  drilldownCloseAction() {
    this.props.closeDrillDown()
    this.props.history.push('/dashboard/sites')
  }

  render() {
    const {
      flags,
      isAdmin,
      t,
      history,
      match: { path },
      collapsed,
      hasAdminMenuAccess,
      canViewSoftwareUpdates,
      canDeviceView,
      canDashboardView,
      canViewOnlyAssignedAsset,
      canUseCounterApp,
      menuValueCollapsable,
      accessToken,
      changeMenuCollapsed,
      canAssetMobileHide,
      canAssetScannerHide,
      canAssetPrinterHide,
      canAssetRFIDHide,
      canAssetOtherHide,
      canAssetRobot,
      canSiteAnalyticsHide,
      isAnomaliesSubscribedFeature,
      isLogAnalyzerSubscribedFeature,
      isWorkForceIntelligenceSubscribedFeature,
      hasOrganization,
      canDeviceLogging,
      canViewAutomations,
      hasDeviceAdminMenuAccess,
      isAutomationSubscribedFeature,
      hasPackageCarSubscribedFeature,
      hasWorkflowInsights
    } = this.props
    return (
    
      <StyledSidebar collapsed={collapsed}>
        {canDashboardView && !canViewOnlyAssignedAsset && <Sidebar.Item
          active={path === '/dashboard/sites'}
          content={t('Dashboards')}
          icon='graph-bar'
          iconRoot='common'
          id = 'dashboardsOption'
          onClick={() => this.drilldownCloseAction()}
        />}
        {flags.anomalyOverview && isAnomaliesSubscribedFeature && (
          <Sidebar.Item
            active={path === '/anomalyOverview'}
            content={t('AnomalyOverview')}
            icon='globe'
            iconRoot='building'
            id = 'anomalyOverviewOption'
            onClick={() => history.push('/anomalyOverview')}
          />
        )}
        {flags.menuIndoorLocation && (
          <Sidebar.Item
            active={path === '/assets/locations'}
            content={t('Locationing')}
            icon='globe'
            iconRoot='building'
            id = 'indoorLocationingOption'
            onClick={() => history.push('/assets/locations')}
          />
        )}
        {canDeviceView && <Sidebar.Submenu
          active={path.includes('/assets/management')}
          content={t('Assets')}
          icon={<div className='icon-assets' />}
          id = 'assetsOption'
          onClick={() => {
            this.getFilters('open', menuValueCollapsable, changeMenuCollapsed)
            changeMenuCollapsed('assetsOpts', !menuValueCollapsable.assetsOpts)
          }}
          open={menuValueCollapsable.assetsOpts}
        >
          <Sidebar.Item
            active={path === '/assets/management/all'}
            content={t('All')}
            icon='devices-multiple'
            id = 'allAssetsOption'
            onClick={() => this.getFilters('all')}
          />
          {canAssetMobileHide
            ? null
            : <Sidebar.Item
              active={path === '/assets/management/mobilecomputer'}
              content={t('Mobile computers')}
              icon='smartphone'
              iconRoot='global'
              id = 'mobileComputersOption'
              onClick={() => this.getFilters('mobilecomputer')}
            />}
          {canAssetScannerHide
            ? null
            : <Sidebar.Item
              active={path === '/assets/management/scanner'}
              content={t('Scanners')}
              icon={<div className='icon-Scanner' />}
              id = 'scannersOption'
              onClick={() => this.getFilters('scanner')}
            />}
          {canAssetPrinterHide
            ? null
            : <Sidebar.Item
              active={path === '/assets/management/printer'}
              content={t('Printers')}
              icon='print'
              id = 'printersOption'
              onClick={() => this.getFilters('printer')}
              />}
          {!hasPackageCarSubscribedFeature
              ? null
              : <Sidebar.Item
                  active={path === '/assets/management/rfidreader'}
                  content={t('RFIDReaders')}
                  icon='reader'
                  id = 'rfidReadersOption'
                  onClick={() => this.getFilters('rfidreader')}
                />}
          {canAssetOtherHide
            ? null
            : <Sidebar.Item
              active={path === '/assets/management/other'}
              content={t('other')}
              icon='device-group'
              id = 'othersOption'
              onClick={() => this.getFilters('other')}
            />}
          {canAssetRobot
            ? <Sidebar.Item
              active={path === '/assets/management/robot'}
              content={t('Robots')}
              id = 'robotsOption'
              icon={<AssetsIcon src={RobotLogo} />}
              onClick={() => { if (!window.location.pathname.includes('robot')) history.push('/assets/management/robot') }}
            />
            : null}
        </Sidebar.Submenu>}
        {flags.siteAnalytics && !canSiteAnalyticsHide && !isMobile() && <Sidebar.Submenu
          content={t('SiteAnalytics')}
          icon='increasing'
          id = 'siteAnalyticsOption'
          onClick={() => {
            changeMenuCollapsed('siteOpts', !menuValueCollapsable.siteOpts)
          }}
          open={menuValueCollapsable.siteOpts}
        >
          {flags.siteComparison && <Sidebar.Item
            active={path === '/siteComparison'}
            content={t('SiteComparison')}
            icon='menu-physical'
            id = 'siteComparisonOption'
            onClick={() => history.push('/siteComparison')}
          />}
          {flags.siteRanking && <Sidebar.Item
            active={path === '/siteRanking'}
            content={t('SiteRanking')}
            icon='submenu'
            id = 'siteRankingOption'
            onClick={() => history.push('/siteRanking')}
          />}
        </Sidebar.Submenu>}
        {hasWorkflowInsights && (
            <Sidebar.Item
              active={path === 'data-solution'}
              content={"Productivity & Insights"}
              icon="line-chart"
              id = 'workflowInsightsOption'
              onClick={() => history.push('/data-solution')}
            />
          )}
        <ManagementSection>
          {canViewSoftwareUpdates && (
            <Sidebar.Item
              active={path === '/assets/updates'}
              content={t('Updates')}
              icon="firmware-update"
              id = 'softwareUpdatesOption'
              onClick={() => history.push('/assets/updates')}
            />
          )}
          {canViewAutomations && isAutomationSubscribedFeature && flags.automationTemplates && (
            <Sidebar.Item
              active={path === 'automation'}
              content={t('MenuOptions_Automation')}
              icon="clock-switchtime"
              id = 'automationOption'
              onClick={() => history.push('/automation')}
            />
          )}
          {flags.gateKeeperMenu && <Sidebar.Item
            active={path === '/assets/check-out'}
            content='Gatekeeper'
            icon='enable-disable'
            iconRoot='common'
            onClick={() => history.push('/assets/gatekeeper')}
          />}
          {hasAdminMenuAccess && this.renderAdminSubmenu(menuValueCollapsable, changeMenuCollapsed)}
          {hasDeviceAdminMenuAccess &&
            <Sidebar.Submenu
              content={t('MenuOptions_DeviceAdmin')}
              icon='device-management'
              iconRoot='common'
              id = 'deviceAdministrationOption'
              onClick={() => {
                changeMenuCollapsed('deviceOpts', !menuValueCollapsable.deviceOpts)
              }}
              open={menuValueCollapsable.deviceOpts}
            >
              {
                canDeviceLogging &&
                <Sidebar.Item
                  active={path === '/device_admin/diagnosticfiles'}
                  content={t('diagnosticFiles')}
                  icon='rate-bounce'
                  id = 'diagnosticFilesOption'
                  onClick={() => history.push('/device_admin/diagnosticfiles')}
                />
              }
              {
                (canViewAutomations && flags.automationRules) &&
                <Sidebar.Item
                  active={path === 'device_admin/rules'}
                  content={t('RulesEngine')}
                  icon='clock-switchtime'
                  id = 'rulesEngineOption'
                  onClick={() => history.push('/device_admin/rules')}
                />
              }
              {
                (flags.rma) &&  
                <Sidebar.Item
                  active={path === '/device_admin/RMAOverview'}
                  content={t('RMAOverview')}
                  icon='device-login'
                  iconRoot='common'
                  id = 'rmaOption'
                  onClick={() => history.push('/device_admin/RMAOverview')}
                />
              }
            </Sidebar.Submenu>}
        </ManagementSection>
        {flags.userReports && isAdmin && !isMobile() &&
          <Sidebar.Submenu
            content={t('Reports')}
            icon='document-report'
            id = 'reportsOption'
            onClick={() => {
              changeMenuCollapsed('reportOpts', !menuValueCollapsable.reportOpts)
            }}
            open={menuValueCollapsable.reportOpts}
          >
            {flags.userActivityReport &&
              <Sidebar.Item
                active={path === '/admin/user_activity_report'}
                content={t('UserActivityReport')}
                icon='document-history'
                id = 'userActivityReportOption'
                onClick={() => history.push('/admin/user_activity_log')}
              />
            }
            {flags.applicationReport &&
              <Sidebar.Item
                active={path === '/reports/application_reports'}
                content={t('ApplicationReport')}
                icon='document-history'
                id = 'applicationReportOption'
                onClick={() => history.push('/reports/application_reports')}
              />
            }
            {flags.contactTracingReport && (
              <Sidebar.Item
                active={path === '/reports/contact_tracing'}
                content={t('Contact Tracing')}
                icon="document-history"
                id = 'contactTracingOption'
                onClick={() => history.push('/reports/contact_tracing')}
              />
            )}
            {flags.eventReport && (
              <Sidebar.Item
                active={path === '/reports/events'}
                content={t('Event Report')}
                icon="document-history"
                id = 'eventsOption'
                onClick={() => history.push('/reports/events')}
              />
            )}
            {flags.eventReports && (
              <Sidebar.Item
                active={path === '/reports/eventReports'}
                content={t('Event Report')}
                icon="document-history"
                id = 'eventReportsOption'
                onClick={() => history.push('/reports/eventReports')}
              />
            )}
            {flags.downloadExports && (
              <Sidebar.Item
                active={path === '/reports/downloads'}
                content={t('Downloads')}
                icon="file-download"
                id = 'downloadsOption'
                onClick={() => history.push('/reports/downloads')}
              />
            )}
          </Sidebar.Submenu>
        }
        {flags.toolsMenu && (
          <Sidebar.Submenu
            content={t('Tools')}
            icon={<Icon root='global' name='tools' />}
            id = 'toolsOption'
            onClick={() => {
              changeMenuCollapsed('toolsOpts', !menuValueCollapsable.toolsOpts)
            }}
            open={menuValueCollapsable.toolsOpts}
          >
            {(flags.peopleCounterApplication && canUseCounterApp) && (
              <Sidebar.Item
                active={path === `/counter/${' '}`}
                content={t('peopleCounter')}
                icon={<Icon root='global' name='users-two' />}
                id = 'peopleCounterOption'
                onClick={() => history.push(`/counter/${' '}`)}
              />
            )}
            <Sidebar.Item
              active={path === `/access_points`}
              content={t('accessPoint')}
              icon={<Icon root='common' name='wifi' />}
              id = 'accessPointsOption'
              onClick={() => history.push(`/access_points`)}
            />
          </Sidebar.Submenu>)}
        {flags.workForceIntelligence && isWorkForceIntelligenceSubscribedFeature &&
          (<Sidebar.Item
            content={t('WorkforceIntelligence')}
            icon='link'
            iconRoot='common'
            id = 'workforceIntelligenceOption'
            onClick={() => redirectToWorkforceIntelligence()}
          />)
        }
        {flags.logAnalyzerOption && isLogAnalyzerSubscribedFeature &&
          (<Sidebar.Item
            content={t('LogAnalyzer')}
            icon='link'
            iconRoot='common'
            id = 'logAnalyzerOption'
            onClick={() => redirectToLogAnalyzer(accessToken)}
          />)
        }
      </StyledSidebar>
    )
  }
}

export default MenuOptions
